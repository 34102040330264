*, ::before, ::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: currentColor;
}

html {
  font-family: 'SalesforceSans', sans-serif;
  font-size: 1em;
  line-height: 1.4;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
}

header,
footer {
  background: var(--white);
}

img, video {
  max-width: 100%;
  height: auto;
}

img {
  border-style: none;
}

ul {
  margin: 0;
  padding: 0;
}

.max-center {
  margin: 0 auto;
  max-width: 690px;
  @media only screen and (--md-desktop) {
    max-width: 1040px;
  }
  @media only screen and (--hd-desktop) {
    max-width: 1180px;
  }
}

.max-center-tight {
  margin: 0 auto;
  max-width: 690px;
  @media only screen and (--md-desktop) {
    max-width: 772px;
  }
  @media only screen and (--hd-desktop) {
    max-width: 874px;
  }

}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

button {
  background: transparent;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  width: max-content;
}

a {
  color: inherit;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
}

.hidden,
[hidden] {
  display: none !important;
}

.clearfix::before,
.clearfix::after {
  content: ' ';
  display: table;
}

.clearfix::after {
  clear: both;
}

@media print,
  (-webkit-min-device-pixel-ratio: 1.25),
  (min-resolution: 1.25dppx),
  (min-resolution: 120dpi) {
}

@media print {
  *,
  *::before,
  *::after {
    background: #ffffff !important;
    box-shadow: none !important;
    /* Black prints faster */
    color: #000000 !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]::after {
    content: ' (' attr(href) ')';
  }

  abbr[title]::after {
    content: ' (' attr(title) ')';
  }

  a[href^='#']::after,
  a[href^='javascript:']::after {
    content: '';
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
  blockquote {
    border: 1px solid #999999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }
}
